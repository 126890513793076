<template>
  <div class="container">
    <div class="login_box">
      <div class="logo">
        <img
          src="../../assets/img/logo.png"
          alt=""
        >
        牵手分享
      </div>
      <div class="login_content">
        <Login
          v-if="showBoxType ==1"
          @changeBox="changeBox"
        ></Login>
        <ForgetPwd
          v-else-if="showBoxType ==2"
          @changeBox="changeBox"
        ></ForgetPwd>
        <Register
          v-else
          @changeBox="changeBox"
        ></Register>
      </div>
    </div>
  </div>
</template>

<script>
const Login = () => import('./Login.vue')
const ForgetPwd = () => import('./ForgetPwd.vue')
const Register = () => import('./Register.vue')
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {
    Login,
    ForgetPwd,
    Register
  },
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      showBoxType: 1 // 1、登录  2、忘记密码  3、注册
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () { },
  mounted () { },
  // 组件方法
  methods: {
    changeBox (type) {
      this.showBoxType = type
    }
  }
}
</script>

<style scoped lang="less">
.container {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/login_bg.jpg") no-repeat;
  background-size: 100% 100%;
  .login_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 60%;
    min-height: 550px;
    min-width: 370px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(6px);
    border-radius: 10px;
    .logo {
      width: 100%;
      height: 55px;
      text-align: center;
      color: #fff;
      font-size: 35px;
      // background-color: pink;
      margin-top: 5%;
      margin-bottom: 3%;
      img {
        height: 100%;
        vertical-align: text-bottom;
        margin-right: 10px;
      }
    }
    .login_content {
      height: 75%;
      width: 350px;
      // background-color: pink;
      margin: 0 auto;
    }
  }
}
</style>
